import img1 from "@/assets/images/about/user/1.jpg";
import img2 from "@/assets/images/about/user/2.jpg";
import img3 from "@/assets/images/about/user/3.jpg";
import img4 from "@/assets/images/about/user/4.jpg";
import img5 from "@/assets/images/about/user/5.jpg";
import img6 from "@/assets/images/about/user/6.jpg";
import img7 from "@/assets/images/about/user/7.jpg";
import img8 from "@/assets/images/about/user/8.jpg";
import img9 from "@/assets/images/about/user/9.jpg";
import img10 from "@/assets/images/about/user/10.jpg";
import img11 from "@/assets/images/about/user/11.jpg";
export const imgurlvalue = [
    {
        url: img1
    },
    {
        url: img2
    },
    {
        url: img3
    },
    {
        url: img4
    },
    {
        url: img5
    },
    {
        url: img6
    },
    {
        url: img7
    },
    {
        url: img8
    },
    {
        url: img9
    },

    {
        url: img10
    },

    {
        url: img11
    }
]